import React from "react"
import SEO from "../components/seo"

const Terms = () => (
  <>
    <SEO title="Terms of Use" />
    <div style={{ maxWidth: 1024, margin: `24px auto`, padding: "64px" }}>
      <p className="c13">
        <span className="c5">Website Terms of Use</span>
      </p>
      <p className="c13">
        <span className="c5">Version 1.1</span>
      </p>
      <p className="c13">
        <span className="c14">Last revised on: </span>
        <span className="c4 c14">August 12, 2020</span>
      </p>
      <p className="c3">
        <span className="c0" />
      </p>
      <p className="c3">
        <span className="c0" />
      </p>
      <p className="c10">
        <span>The website located at gatsbyjs.com (the “</span>
        <span className="c14">Site</span>
        <span>”) is a copyrighted work belonging to Gatsby Inc (“</span>
        <span className="c14">Company</span>
        <span>”, “</span>
        <span className="c14">us</span>
        <span>”, “</span>
        <span className="c14">our</span>
        <span>”, and “</span>
        <span className="c14">we</span>
        <span className="c0">
          ”). &nbsp;Certain features of the Site may be subject to additional
          guidelines, terms, or rules, which will be posted on the Site in
          connection with such features. &nbsp;All such additional terms,
          guidelines, and rules are incorporated by reference into these Terms.
        </span>
      </p>
      <p className="c10">
        <span>THESE TERMS OF USE (THESE “</span>
        <span className="c14">TERMS</span>
        <span className="c0">
          ”) SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR
          USE OF THE SITE. &nbsp;BY ACCESSING OR USING THE SITE, YOU ARE
          ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
          REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT,
          AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF
          YOURSELF OR THE ENTITY THAT YOU REPRESENT). &nbsp;YOU MAY NOT ACCESS
          OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS
          OLD. &nbsp;IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE
          TERMS, DO NOT ACCESS AND/OR USE THE SITE.
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 8.2) ON AN
          INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS
          ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF
          A DISPUTE.
        </span>
      </p>
      <ol className="c12 lst-kix_list_8-0 start" start={1}>
        <li className="c10 c16" id="h.gjdgxs">
          <span className="c5">Accounts </span>
        </li>
      </ol>
      <ol className="c12 lst-kix_list_8-1 start" start={1}>
        <li className="c2" id="h.30j0zll">
          <span className="c6">Account Creation. &nbsp;</span>
          <span className="c0">
            In order to use certain features of the Site, you must register for
            an account (“
          </span>
          <span className="c6">Account</span>
          <span className="c0">
            ”) and provide certain information about yourself as prompted by the
            account registration form. &nbsp;You represent and warrant that: (a)
            all required registration information you submit is truthful and
            accurate; (b) you will maintain the accuracy of such information.
            You may delete your Account at any time, for any reason, by
            following the instructions on the Site. &nbsp;Company may suspend or
            terminate your Account in accordance with Section 7.{` `}
          </span>
        </li>
        <li className="c2">
          <span className="c6">Account Responsibilities.</span>
          <span className="c0">
            &nbsp; You are responsible for maintaining the confidentiality of
            your Account login information and are fully responsible for all
            activities that occur under your Account. &nbsp;You agree to
            immediately notify Company of any unauthorized use, or suspected
            unauthorized use of your Account or any other breach of security.
            &nbsp;Company cannot and will not be liable for any loss or damage
            arising from your failure to comply with the above requirements.
          </span>
        </li>
        <li className="c2">
          <span className="c6">Account Usage Limits.</span>
          <span className="c0">
            &nbsp; We reserve the right to enforce{" "}
            <a href="https://support.gatsbyjs.com/hc/en-us/articles/360052326714-Platform-Limits">
              platform limits
            </a>
            . Company may suspend or terminate your Account in accordance with
            these limits.
          </span>
        </li>
      </ol>
      <ol className="c12 lst-kix_list_8-0" start={2}>
        <li className="c10 c16">
          <span className="c5">Access to the Site </span>
        </li>
      </ol>
      <ol className="c12 lst-kix_list_8-1 start" start={1}>
        <li className="c2" id="h.1fob9te">
          <span className="c6">License. </span>
          <span className="c0">
            &nbsp;Subject to these Terms, Company grants you a non-transferable,
            non-exclusive, revocable, limited license to use and access the Site
            solely for your own personal, noncommercial use.
          </span>
        </li>
        <li className="c2" id="h.3znysh7">
          <span className="c6">Certain Restrictions. &nbsp;</span>
          <span className="c0">
            The rights granted to you in these Terms are subject to the
            following restrictions: (a) you shall not license, sell, rent,
            lease, transfer, assign, distribute, host, or otherwise commercially
            exploit the Site, whether in whole or in part, or any content
            displayed on the Site; (b) you shall not modify, make derivative
            works of, disassemble, reverse compile or reverse engineer any part
            of the Site; (c) you shall not access the Site in order to build a
            similar or competitive website, product, or service; and (d) except
            as expressly stated herein, no part of the Site may be copied,
            reproduced, distributed, republished, downloaded, displayed, posted
            or transmitted in any form or by any means. &nbsp;Unless otherwise
            indicated, any future release, update, or other addition to
            functionality of the Site shall be subject to these Terms. &nbsp;All
            copyright and other proprietary notices on the Site (or on any
            content displayed on the Site) must be retained on all copies
            thereof.
          </span>
        </li>
        <li className="c2" id="h.2et92p0">
          <span className="c6">Modification.</span>
          <span className="c0">
            &nbsp; Company reserves the right, at any time, to modify, suspend,
            or discontinue the Site (in whole or in part) with or without notice
            to you. &nbsp;You agree that Company will not be liable to you or to
            any third party for any modification, suspension, or discontinuation
            of the Site or any part thereof.
          </span>
        </li>
        <li className="c2" id="h.tyjcwt">
          <span className="c6">No Support or Maintenance.</span>
          <span className="c0">
            &nbsp; You acknowledge and agree that Company will have no
            obligation to provide you with any support or maintenance in
            connection with the Site.
          </span>
        </li>
        <li className="c2" id="h.3dy6vkm">
          <span className="c5">Ownership. &nbsp;</span>
          <span className="c0">
            You acknowledge that all the intellectual property rights, including
            copyrights, patents, trade marks, and trade secrets, in the Site and
            its content are owned by Company or Company’s suppliers.
            &nbsp;Neither these Terms (nor your access to the Site) transfers to
            you or any third party any rights, title or interest in or to such
            intellectual property rights, except for the limited access rights
            expressly set forth in Section 2.1. Company and its suppliers
            reserve all rights not granted in these Terms. &nbsp;There are no
            implied licenses granted under these Terms.
          </span>
        </li>
      </ol>
      <ol className="c12 lst-kix_list_8-0" start={3}>
        <li className="c10 c16" id="h.1t3h5sf">
          <span className="c6">Indemnification. &nbsp; </span>
          <span className="c0">
            You agree to indemnify and hold Company (and its officers,
            employees, and agents) harmless, including costs and attorneys’
            fees, from any claim or demand made by any third party due to or
            arising out of (a) your use of the Site, (b) your violation of these
            Terms or (c) your violation of applicable laws or regulations.
            &nbsp;Company reserves the right, at your expense, to assume the
            exclusive defense and control of any matter for which you are
            required to indemnify us, and you agree to cooperate with our
            defense of these claims. &nbsp;You agree not to settle any matter
            without the prior written consent of Company. &nbsp;Company will use
            reasonable efforts to notify you of any such claim, action or
            proceeding upon becoming aware of it.
          </span>
        </li>
        <li className="c10 c16">
          <span className="c5">Third-Party Links &amp; Ads; Other Users</span>
        </li>
      </ol>
      <ol className="c12 lst-kix_list_8-1 start" start={1}>
        <li className="c2">
          <span className="c6">Third-Party Links &amp; Ads. &nbsp;</span>
          <span className="c0">
            The Site may contain links to third-party websites and services,
            and/or display advertisements for third parties (collectively, “
          </span>
          <span className="c6">Third-Party Links &amp; Ads</span>
          <span className="c0">
            ”). &nbsp;Such Third-Party Links &amp; Ads are not under the control
            of Company, and Company is not responsible for any Third-Party Links
            &amp; Ads. &nbsp;Company provides access to these Third-Party Links
            &amp; Ads only as a convenience to you, and does not review,
            approve, monitor, endorse, warrant, or make any representations with
            respect to Third-Party Links &amp; Ads. &nbsp;You use all
            Third-Party Links &amp; Ads at your own risk, and should apply a
            suitable level of caution and discretion in doing so. When you click
            on any of the Third-Party Links &amp; Ads, the applicable third
            party’s terms and policies apply, including the third party’s
            privacy and data gathering practices. &nbsp;You should make whatever
            investigation you feel necessary or appropriate before proceeding
            with any transaction in connection with such Third-Party Links &amp;
            Ads.
          </span>
        </li>
        <li className="c2">
          <span className="c6">Other Users. &nbsp;</span>
          <span className="c0">
            &nbsp;Your interactions with other Site users are solely between you
            and such users. &nbsp;You agree that Company will not be responsible
            for any loss or damage incurred as the result of any such
            interactions. &nbsp;If there is a dispute between you and any Site
            user, we are under no obligation to become involved.
          </span>
        </li>
        <li className="c2">
          <span className="c6">Release. </span>
          <span className="c0">
            &nbsp;You hereby release and forever discharge the Company (and our
            officers, employees, agents, successors, and assigns) from, and
            hereby waive and relinquish, each and every past, present and future
            dispute, claim, controversy, demand, right, obligation, liability,
            action and cause of action of every kind and nature (including
            personal injuries, death, and property damage), that has arisen or
            arises directly or indirectly out of, or that relates directly or
            indirectly to, the Site (including any interactions with, or act or
            omission of, other Site users or any Third-Party Links &amp; Ads).
            &nbsp;IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA
            CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH
            STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
            CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT
            THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST
            HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
          </span>
        </li>
      </ol>
      <ol className="c12 lst-kix_list_8-0" start={5}>
        <li className="c10 c16" id="h.4d34og8">
          <span className="c6">Disclaimers </span>
        </li>
      </ol>
      <p className="c15">
        <span className="c0">
          THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND
          COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES
          AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY,
          INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
          NON-INFRINGEMENT. &nbsp;WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT
          THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE
          ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE,
          LEGAL, OR SAFE. &nbsp;IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH
          RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO
          NINETY (90) DAYS FROM THE DATE OF FIRST USE.
        </span>
      </p>
      <p className="c15 c19">
        <span className="c0" />
      </p>
      <p className="c15">
        <span className="c0">
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES,
          SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. &nbsp;SOME JURISDICTIONS
          DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE
          ABOVE LIMITATION MAY NOT APPLY TO YOU. &nbsp;
        </span>
      </p>
      <p className="c3">
        <span className="c0" />
      </p>
      <ol className="c12 lst-kix_list_8-0" start={6}>
        <li className="c10 c16" id="h.2s8eyo1">
          <span className="c6">Limitation on Liability</span>
        </li>
      </ol>
      <p className="c10">
        <span className="c0">
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR
          OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST
          PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR
          ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
          PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE
          OF, OR INABILITY TO USE, THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES. &nbsp;ACCESS TO, AND USE OF, THE SITE
          IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE
          FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA
          RESULTING THEREFROM. &nbsp;
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO
          THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES
          ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER
          AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE
          LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF
          MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. &nbsp;YOU AGREE THAT
          OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR
          RELATING TO THIS AGREEMENT.
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
          LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. &nbsp;{` `}
        </span>
      </p>
      <ol className="c12 lst-kix_list_8-0" start={7}>
        <li className="c10 c16" id="h.17dp8vu">
          <span className="c14 c17">Term and Termination. &nbsp; </span>
          <span className="c0">
            Subject to this Section, these Terms will remain in full force and
            effect while you use the Site. &nbsp;We may suspend or terminate
            your rights to use the Site (including your Account) at any time for
            any reason at our sole discretion, including for any use of the Site
            in violation of these Terms. &nbsp;Upon termination of your rights
            under these Terms, your Account and right to access and use the Site
            will terminate immediately. &nbsp; Company will not have any
            liability whatsoever to you for any termination of your rights under
            these Terms, including for termination of your Account. &nbsp;Even
            after your rights under these Terms are terminated, the following
            provisions of these Terms will remain in effect: Sections 2.2
            through 2.5 and Sections 3 through 8.
          </span>
        </li>
      </ol>
      <p className="c15">
        <span className="c0">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <ol className="c12 lst-kix_list_8-0" start={8}>
        <li className="c10 c16" id="h.3rdcrjn">
          <span className="c6">General</span>
        </li>
      </ol>
      <ol className="c12 lst-kix_list_8-1 start" start={1}>
        <li className="c2" id="h.26in1rg">
          <span className="c6">Changes.</span>
          <span className="c0">
            &nbsp; These Terms are subject to occasional revision, and if we
            make any substantial changes, we may notify you by sending you an
            e-mail to the last e-mail address you provided to us (if any),
            and/or by prominently posting notice of the changes on our Site.
            &nbsp;You are responsible for providing us with your most current
            e-mail address. &nbsp;In the event that the last e-mail address that
            you have provided us is not valid, or for any reason is not capable
            of delivering to you the notice described above, our dispatch of the
            e-mail containing such notice will nonetheless constitute effective
            notice of the changes described in the notice. &nbsp;Any changes to
            these Terms will be effective upon the earlier of thirty (30)
            calendar days following our dispatch of an e-mail notice to you (if
            applicable) or thirty (30) calendar days following our posting of
            notice of the changes on our Site. &nbsp;These changes will be
            effective immediately for new users of our Site. &nbsp;Continued use
            of our Site following notice of such changes shall indicate your
            acknowledgement of such changes and agreement to be bound by the
            terms and conditions of such changes.
          </span>
        </li>
        <li className="c2" id="h.lnxbz9">
          <span className="c6">Dispute Resolution. </span>
          <span className="c9">
            Please read this Arbitration Agreement carefully. &nbsp;It is part
            of your contract with Company and affects your rights. &nbsp;It
            contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS
            ACTION WAIVER.
          </span>
        </li>
      </ol>
      <ol className="c12 lst-kix_list_8-2 start" start={1}>
        <li className="c10 c11">
          <span className="c8">Applicability of Arbitration Agreement.</span>
          <span className="c0">
            &nbsp; All claims and disputes (excluding claims for injunctive or
            other equitable relief as set forth below) in connection with the
            Terms or the use of any product or service provided by the Company
            that cannot be resolved informally or in small claims court shall be
            resolved by binding arbitration on an individual basis under the
            terms of this Arbitration Agreement. &nbsp;Unless otherwise agreed
            to, all arbitration proceedings shall be held in English. &nbsp;This
            Arbitration Agreement applies to you and the Company, and to any
            subsidiaries, affiliates, agents, employees, predecessors in
            interest, successors, and assigns, as well as all authorized or
            unauthorized users or beneficiaries of services or goods provided
            under the Terms.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">
            Notice Requirement and Informal Dispute Resolution
          </span>
          <span className="c0">
            . &nbsp;Before either party may seek arbitration, the party must
            first send to the other party a written Notice of Dispute (
          </span>
          <span className="c6">“Notice”</span>
          <span className="c0">
            ) describing the nature and basis of the claim or dispute, and the
            requested relief. &nbsp;A Notice to the Company should be sent to:
            548 Market St #36791, San Francisco, California 94104. &nbsp;After
            the Notice is received, you and the Company may attempt to resolve
            the claim or dispute informally. &nbsp;If you and the Company do not
            resolve the claim or dispute within thirty (30) days after the
            Notice is received, either party may begin an arbitration
            proceeding. &nbsp;The amount of any settlement offer made by any
            party may not be disclosed to the arbitrator until after the
            arbitrator has determined the amount of the award, if any, to which
            either party is entitled.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c0">
            Arbitration Rules. &nbsp;Arbitration shall be initiated through the
            American Arbitration Association (
          </span>
          <span className="c6">“AAA”</span>
          <span className="c0">
            ), an established alternative dispute resolution provider (
          </span>
          <span className="c6">“ADR Provider”</span>
          <span className="c0">
            ) that offers arbitration as set forth in this section. &nbsp;If AAA
            is not available to arbitrate, the parties shall agree to select an
            alternative ADR Provider. &nbsp;The rules of the ADR Provider shall
            govern all aspects of the arbitration, including but not limited to
            the method of initiating and/or demanding arbitration, except to the
            extent such rules are in conflict with the Terms. &nbsp;The AAA
            Consumer Arbitration Rules (
          </span>
          <span className="c6">“Arbitration Rules”</span>
          <span className="c0">
            ) governing the arbitration are available online at{` `}
          </span>
          <span className="c7">
            <a
              className="c18"
              href="https://www.google.com/url?q=http://www.adr.org&sa=D&ust=1553067645133000"
            >
              www.adr.org
            </a>
          </span>
          <span className="c0">
            &nbsp;or by calling the AAA at 1-800-778-7879. &nbsp;The arbitration
            shall be conducted by a single, neutral arbitrator. &nbsp;Any claims
            or disputes where the total amount of the award sought is less than
            Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through
            binding non-appearance-based arbitration, at the option of the party
            seeking relief. &nbsp;For claims or disputes where the total amount
            of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or
            more, the right to a hearing will be determined by the Arbitration
            Rules. &nbsp;Any hearing will be held in a location within 100 miles
            of your residence, unless you reside outside of the United States,
            and unless the parties agree otherwise. &nbsp;If you reside outside
            of the U.S., the arbitrator shall give the parties reasonable notice
            of the date, time and place of any oral hearings. Any judgment on
            the award rendered by the arbitrator may be entered in any court of
            competent jurisdiction. &nbsp;If the arbitrator grants you an award
            that is greater than the last settlement offer that the Company made
            to you prior to the initiation of arbitration, the Company will pay
            you the greater of the award or $2,500.00. &nbsp;Each party shall
            bear its own costs (including attorney’s fees) and disbursements
            arising out of the arbitration and shall pay an equal share of the
            fees and costs of the ADR Provider.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">
            Additional Rules for Non-Appearance Based Arbitration
          </span>
          <span className="c0">
            . &nbsp;If non-appearance based arbitration is elected, the
            arbitration shall be conducted by telephone, online and/or based
            solely on written submissions; the specific manner shall be chosen
            by the party initiating the arbitration. &nbsp;The arbitration shall
            not involve any personal appearance by the parties or witnesses
            unless otherwise agreed by the parties.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Time Limits.</span>
          <span className="c0">
            &nbsp; If you or the Company pursue arbitration, the arbitration
            action must be initiated and/or demanded within the statute of
            limitations (i.e., the legal deadline for filing a claim) and within
            any deadline imposed under the AAA Rules for the pertinent claim.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Authority of Arbitrator</span>
          <span className="c0">
            . &nbsp;If arbitration is initiated, the arbitrator will decide the
            rights and liabilities, if any, of you and the Company, and the
            dispute will not be consolidated with any other matters or joined
            with any other cases or parties. &nbsp;The arbitrator shall have the
            authority to grant motions dispositive of all or part of any claim.
            &nbsp;The arbitrator shall have the authority to award monetary
            damages, and to grant any non-monetary remedy or relief available to
            an individual under applicable law, the AAA Rules, and the Terms.
            &nbsp;The arbitrator shall issue a written award and statement of
            decision describing the essential findings and conclusions on which
            the award is based, including the calculation of any damages
            awarded. &nbsp;The arbitrator has the same authority to award relief
            on an individual basis that a judge in a court of law would have.
            &nbsp;The award of the arbitrator is final and binding upon you and
            the Company.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Waiver of Jury Trial.</span>
          <span className="c0">
            &nbsp; THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY
            RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A
            JURY, instead electing that all claims and disputes shall be
            resolved by arbitration under this Arbitration Agreement.
            &nbsp;Arbitration procedures are typically more limited, more
            efficient and less costly than rules applicable in a court and are
            subject to very limited review by a court. &nbsp;In the event any
            litigation should arise between you and the Company in any state or
            federal court in a suit to vacate or enforce an arbitration award or
            otherwise, YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL,
            instead electing that the dispute be resolved by a judge.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Waiver of Class or Consolidated Actions</span>
          <span className="c0">
            . &nbsp;ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
            AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND
            NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER
            CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE
            OF ANY OTHER CUSTOMER OR USER. &nbsp;
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Confidentiality</span>
          <span className="c0">
            . &nbsp;All aspects of the arbitration proceeding, including but not
            limited to the award of the arbitrator and compliance therewith,
            shall be strictly confidential. &nbsp;The parties agree to maintain
            confidentiality unless otherwise required by law. &nbsp;This
            paragraph shall not prevent a party from submitting to a court of
            law any information necessary to enforce this Agreement, to enforce
            an arbitration award, or to seek injunctive or equitable relief.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Severability</span>
          <span className="c0">
            . &nbsp;If any part or parts of this Arbitration Agreement are found
            under the law to be invalid or unenforceable by a court of competent
            jurisdiction, then such specific part or parts shall be of no force
            and effect and shall be severed and the remainder of the Agreement
            shall continue in full force and effect.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Right to Waive.</span>
          <span className="c0">
            &nbsp; Any or all of the rights and limitations set forth in this
            Arbitration Agreement may be waived by the party against whom the
            claim is asserted. &nbsp;Such waiver shall not waive or affect any
            other portion of this Arbitration Agreement.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Survival of Agreement</span>
          <span className="c0">
            . &nbsp;This Arbitration Agreement will survive the termination of
            your relationship with Company. &nbsp;
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Small Claims Court.</span>
          <span className="c0">
            &nbsp; Notwithstanding the foregoing, either you or the Company may
            bring an individual action in small claims court.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Emergency Equitable Relief</span>
          <span className="c0">
            . &nbsp;Notwithstanding the foregoing, either party may seek
            emergency equitable relief before a state or federal court in order
            to maintain the status quo pending arbitration. &nbsp;A request for
            interim measures shall not be deemed a waiver of any other rights or
            obligations under this Arbitration Agreement.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Claims Not Subject to Arbitration. </span>
          <span className="c0">
            &nbsp;Notwithstanding the foregoing, claims of defamation, violation
            of the Computer Fraud and Abuse Act, and infringement or
            misappropriation of the other party’s patent, copyright, trademark
            or trade secrets shall not be subject to this Arbitration Agreement.
          </span>
        </li>
        <li className="c10 c11">
          <span className="c8">Courts.</span>
          <span className="c0">
            &nbsp; In any circumstances where the foregoing Arbitration
            Agreement permits the parties to litigate in court, the parties
            hereby agree to submit to the personal jurisdiction of the courts
            located within San Francisco County, California, for such purpose
            {` `}
          </span>
        </li>
      </ol>
      <ol className="c12 lst-kix_list_8-1" start={3}>
        <li className="c2">
          <span className="c6">Export. </span>
          <span className="c0">
            The Site may be subject to U.S. export control laws and may be
            subject to export or import regulations in other countries. You
            agree not to export, reexport, or transfer, directly or indirectly,
            any U.S. technical data acquired from Company, or any products
            utilizing such data, in violation of the United States export laws
            or regulations.
            {` `}
          </span>
        </li>
        <li className="c2">
          <span className="c6">Disclosures.</span>
          <span className="c0">
            &nbsp; Company is located at the address in Section 8.8. If you are
            a California resident, you may report complaints to the Complaint
            Assistance Unit of the Division of Consumer Product of the
            California Department of Consumer Affairs by contacting them in
            writing at 400 R Street, Sacramento, CA 95814, or by telephone at
            (800) 952-5210.
          </span>
        </li>
        <li className="c2">
          <span className="c6">Electronic Communications.</span>
          <span className="c0">
            &nbsp; The communications between you and Company use electronic
            means, whether you use the Site or send us emails, or whether
            Company posts notices on the Site or communicates with you via
            email. For contractual purposes, you (a) consent to receive
            communications from Company in an electronic form; and (b) agree
            that all terms and conditions, agreements, notices, disclosures, and
            other communications that Company provides to you electronically
            satisfy any legal requirement that such communications would satisfy
            if it were be in a hardcopy writing. The foregoing does not affect
            your non-waivable rights.
          </span>
        </li>
        <li className="c2">
          <span className="c6">Entire Terms.</span>
          <span className="c0">
            &nbsp;These Terms constitute the entire agreement between you and us
            regarding the use of the Site. Our failure to exercise or enforce
            any right or provision of these Terms shall not operate as a waiver
            of such right or provision. The section titles in these Terms are
            for convenience only and have no legal or contractual effect. The
            word “including” means “including without limitation”. &nbsp;If any
            provision of these Terms is, for any reason, held to be invalid or
            unenforceable, the other provisions of these Terms will be
            unimpaired and the invalid or unenforceable provision will be deemed
            modified so that it is valid and enforceable to the maximum extent
            permitted by law. &nbsp;Your relationship to Company is that of an
            independent contractor, and neither party is an agent or partner of
            the other. &nbsp;These Terms, and your rights and obligations
            herein, may not be assigned, subcontracted, delegated, or otherwise
            transferred by you without Company’s prior written consent, and any
            attempted assignment, subcontract, delegation, or transfer in
            violation of the foregoing will be null and void. &nbsp;Company may
            freely assign these Terms. &nbsp;The terms and conditions set forth
            in these Terms shall be binding upon assignees.{` `}
          </span>
        </li>
        <li className="c2" id="h.35nkun2">
          <span className="c6">Copyright/Trademark Information</span>
          <span className="c0">. &nbsp;Copyright © </span>
          <span className="c0 c4">2019</span>
          <span className="c0">&nbsp;</span>
          <span className="c0">
            Gatsby Inc. All rights reserved. &nbsp;All trademarks, logos and
            service marks (“
          </span>
          <span className="c6">Marks</span>
          <span className="c0">
            ”) displayed on the Site are our property or the property of other
            third parties. You are not permitted to use these Marks without our
            prior written consent or the consent of such third party which may
            own the Marks.
          </span>
        </li>
        <li className="c2" id="h.1ksv4uv">
          <span className="c6">Contact Information:</span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0">Sam Bhagwat</span>
      </p>
      <p className="c1">
        <span className="c0">Address: </span>
      </p>
      <p className="c1">
        <span className="c0">548 Market St #36791</span>
      </p>
      <p className="c1">
        <span className="c0">San Francisco, California 94104</span>
      </p>
      <p className="c1">
        <span className="c20">Email: legal@gatsbyjs.com</span>
      </p>
      <div>
        <p className="c21">
          <span className="c0">&nbsp;</span>
        </p>
      </div>
    </div>
  </>
)

export default Terms
